import React from "react";
import image from "./sample.png";

export default function About() {
  return (
    <section className="About">
      <div className="about-body">
        <div className="about-content">
          <h2>About Us</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. provident
            g elit. Nobis, voluptatum quod fugit vel deleniti provident dicta
            nesciunt quibusdam amet amet consectetur, adipisicing elit. Nobis,
            voluptatum quod fugit vel deleniti provident dicta nesciunt quibusd
            quos autem atque hic .odit modi molestias.
          </p>
        </div>

        <div className="image-container">
          <img src={image} alt="About-image" />
        </div>
      </div>
    </section>
  );
}
