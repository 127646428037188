import React from "react";
import image from "./sample.png";

export default function Contact() {
  return (
    <div>
      <section className="About">
        <div className="about-body">
          <div className="about-content">
            <h2>Contact Us</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              providing elit. voluptatum quod fugit vel deleniti
              provident quibusdam amet amet consectetur, adipisicing elit.
              Nobis, voluptatum quod fugit vel deleniti provident nesciunt
              quibusd quos autem a doloribus sed nihil atque hic .odit modi
              molestias.
            </p>
          </div>

          <div className="image-container">
            <img src={image} alt="Contact-image" />
          </div>
        </div>
      </section>
    </div>
  );
}
