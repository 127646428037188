import React from "react";
import { Link } from "react-router-dom";
import "./App.css";

function Header() {
  return (
    <nav className="navbar">
      <h1>Aion AidDesk</h1>
      <div className="Card">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/Contact">Contact</Link>
          </li>
          <li>
            <Link to="/About">About</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
