import React, { useState, useEffect } from "react";
import Records from "./data.json"; // Use this if you want local data
// import { fetchData } from '../services/api'; // Uncomment if using API
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";

const Home = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        // Uncomment the line below if using the API
        // const data = await fetchData();
        const data = Records; // Use local data for now
        setContent(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderRef = React.useRef(null);

  return (
    <div className="main-container">
      <div className="container">
        <BsChevronCompactLeft
          className="Left-arrow"
          onClick={() => sliderRef.current.slickPrev()}
        />
        <div className="row-container">
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}
          {!loading && !error && (
            <Slider ref={sliderRef} {...settings}>
              {content.map((item) => (
                <div key={item.id} className="row">
                  <img src={item.image} alt={item.title} />
                  <div className="Image-Details">
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
        <BsChevronCompactRight
          className="Right-arrow"
          onClick={() => sliderRef.current.slickNext()}
        />
      </div>
    </div>
  );
};

export default Home;
